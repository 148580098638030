export default [
	{
		code: 'en',
		file: 'en.js',
	},
	{
		code: 'nl',
		file: 'nl.js',
	},
];
